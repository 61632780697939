/* global Promise */
import getCookie from './getCookie';

/**
 * Checks to see if window.heap exists — then finds the Heap Cookie information; Exports that value as a global var
 */
let heapCookie = null;
let isWaitingForCookie = false;
let heapCookieData;

/* exporting these functions so we can override the heapCookie & heapCookieData variables in lead-form (leads widget) */
function setHeapCookie(name) {
	heapCookie = name;
}

function setHeapCookieData(data) {
	heapCookieData = data;
}

const heapCookiePromise = new Promise((resolve) => {
	const heapHasLoaded = () => window.heap && window.heap.appid;
	const getHeapCookie = () => getCookie('_hp2_id.' + window.heap.appid);

	const observer = new MutationObserver(() => {
		if (heapHasLoaded()) {
			const waitForCookie = () => {
				if (isWaitingForCookie) {
					return;
				}

				isWaitingForCookie = true;

				const cookieValue = getHeapCookie();
				if (cookieValue) {
					resolve(cookieValue);
				} else {
					setTimeout(() => {
						isWaitingForCookie = false;
						waitForCookie();
					}, 150);
				}
			};

			waitForCookie();
		}
	});
	observer.observe(document.documentElement, { childList: true, subtree: true });
});

if (window.Modalytics && !window.Modalytics.privacyPreferencesDetected) {
	heapCookiePromise.then((resolvedHeapCookie) => {
		setHeapCookie(resolvedHeapCookie);
		setHeapCookieData(JSON.parse(decodeURIComponent(heapCookie)));

		const sendHeapDataToFrames = new CustomEvent('heapCookieResolved', {
			detail: { heapCookie, heapCookieData }
		});

		window.dispatchEvent(sendHeapDataToFrames);
	});
}

export { heapCookie, heapCookieData, setHeapCookie, setHeapCookieData };
